<template>
  <div>

    <v-row justify="end">
      <v-col class="flex_end">
        <v-btn
          color="primary"
          tile
          depressed
          @click="contactAction('addNew')"
        >Dodaj osobę kontaktową</v-btn>
      </v-col>
    </v-row>

    <div v-for="(item,index) in contacts_person" :key="index">
      <v-row class="flex_end">
        <div style="display: flex; flex-grow: 1">
          <v-col>
            <v-text-field
              v-model="contacts_person[index].name"
              label="Nazwa osoby kontaktowej"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="contacts_person[index].tel"
              label="Telefon"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="contacts_person[index].email"
              label="E-mail"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="contacts_person[index].position"
              label="Stanowisko"
              @input="updateComponent()"
            ></v-text-field>
          </v-col>
        </div>
        
        <div class="flex_end" style="margin: 0 15px">
          <v-btn
            icon
            color="error"
            @click="contactAction('remove',index)"
            v-if="contacts_person.length > 1"
          ><v-icon>mdi-minus</v-icon></v-btn>
        </div>
      </v-row>
    </div>
  
    
  </div>
</template>

<script>
export default {
  props: {
    contacts_person: Array,
  },
  data: () => ({

  }),
  methods: {
    contactAction(param, num = 0){
      // Dodaj nową osobę kontaktową
      if(param == 'addNew'){
        this.contacts_person.push({name: '',tel: '',email: '',position: ''});
      }
      // Usuwanie osoby kontaktowej
      else if(param == 'remove'){
        this.contacts_person.splice(num,1);
      }
    },
    updateComponent(){
      this.$emit('update_me',this.contacts_person)
    }
  },
  mounted(){
  }
};
</script>

<style lang="scss" scoped>
</style>