<template>
  <div class="home">

    <breadcrumb :items="breadcrumb" />

    <v-row>
      <v-col>

        <v-card flat class="card_action">
          <v-card-actions>
            <v-row align-items="center">
              <v-col class="flex_end">
                <v-btn
                  color="error"
                  depressed
                  tile
                  @click="dialog = true"
                >
                  Usuń
                </v-btn>
                <v-btn
                  color="primary"
                  depressed
                  tile
                  @click="edit_user()"
                >
                  Zapisz zmiany
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>

        <v-form
          ref="form" 
          v-model="valid"
          lazy-validation
        >
          <v-card>
            <v-card-title>Dane Klienta</v-card-title>
            <v-card-text>
              <v-row>
                <v-col>

                  <v-text-field
                    v-model="user_data.name"
                    label="Nazwa"
                    color="primary"
                    :rules="$store.state.rules.not_null"
                  ></v-text-field>

                  <v-text-field
                    v-model="user_data.full_name"
                    label="Pełna nazwa"
                    color="primary"
                    :rules="$store.state.rules.not_null"
                  ></v-text-field>

                  <v-text-field
                    v-model="user_data.email"
                    label="Email"
                    color="primary"
                    :rules="$store.state.rules.not_null"
                  ></v-text-field>

                  <v-text-field
                    v-model="user_data.nip"
                    label="NIP"
                    color="primary"
                  ></v-text-field>

                  <v-text-field
                    v-model="user_data.city"
                    label="Miasto"
                    color="primary"
                  ></v-text-field>

                  <v-text-field
                    v-model="user_data.zip_code"
                    label="Kod pocztowy"
                    color="primary"
                  ></v-text-field>

                  <v-text-field
                    v-model="user_data.address"
                    label="Adres"
                    color="primary"
                  ></v-text-field>

                  <!-- <v-select
                    v-model="user_data.role_id"
                    :items="role"
                    item-text="name"
                    item-value="id"
                    label="Rola"
                    single-line
                    :rules="$store.state.rules.not_null"
                  ></v-select> -->

                  <v-autocomplete
                    v-model="user_data.role_id"
                    :items="role"
                    item-text="name"
                    item-value="id"
                    no-data-text="Brak wyników"
                    label="Rola"
                    :rules="$store.state.rules.not_null"
                  ></v-autocomplete>


                  <v-text-field
                    v-model="user_data.recommending_user"
                    label="Osoba polecająca"
                    color="primary"
                  ></v-text-field>
                
                </v-col>
              </v-row>              

              <Contacts
                :contacts_person="contacts_person"
                @update_me="updateContacts"
              />

            </v-card-text>
          </v-card>
        </v-form>

      </v-col>
    </v-row>
    
    <confirm_dialog
      v-if="dialog"
      text="Czy na pewno chcesz usuąć Klienta?"
      @close="dialog = false;"
      @confirm="delete_user()"
    />
    
  </div>
</template>

<script>
import Contacts from '@/components/Customers/Contacts.vue';

export default {
  components: {
    Contacts,
  },
  data: () => ({
    id: 0,
    valid: false,
    dialog: false,
    breadcrumb: [
      {
        text: 'Pulpit',
        disabled: false,
        to: '/panel',
      },
      {
        text: 'Lista Klientów',
        disabled: false,
        to: '/klienci',
      },
      {
        text: 'Podgląd Klienta',
        disabled: false,
        to: '/edit',
      },
      {
        text: 'Edytuj Klienta',
        disabled: true,
        to: '/edit',
      }
    ],

    role: [
      {
        id: 0,
        name: ''
      },
    ],

    user_data: {
      id: 1,
      email: 'Rzeszów',
      name: 'Andrzej Maślak z ZOO',
      numebr: '1233',
      recommending_user: '',
      role_id: 0,
    },
    
    contacts_person: [{
      name: '',
      tel: '',
      email: '',
      position: ''
    }],
  }),
  methods: {
    updateContacts(v){
      this.contacts_person = v;
    },
    edit_user() {
      if(this.$refs.form.validate()) {
        this.$store.commit('loader');

        let formData = new FormData();
        formData.append("name", this.user_data.name);
        formData.append("email", this.user_data.email);
        if(this.user_data.recommending_user) formData.append("recommending_user", this.user_data.recommending_user);
        formData.append("role_id", this.user_data.role_id);
        formData.append("nip", this.user_data.nip);

        formData.append("full_name", this.user_data.full_name);
        formData.append("city", this.user_data.city);
        formData.append("address", this.user_data.address);
        formData.append("zip_code", this.user_data.zip_code);

        // Dodawanie osob kontaktowych
        if(this.contacts_person[0].name.length) {
          for(let item in this.contacts_person){
            formData.append("contact["+item+"][name]", this.contacts_person[item].name == null ? '' : this.contacts_person[item].name);
            formData.append("contact["+item+"][tel]", this.contacts_person[item].tel == null ? '' : this.contacts_person[item].tel);
            formData.append("contact["+item+"][email]", this.contacts_person[item].email == null ? '' : this.contacts_person[item].email);
            formData.append("contact["+item+"][position]", this.contacts_person[item].position == null ? '' : this.contacts_person[item].position);
          }
        }

        this.$axios({url: this.$store.state.api +'/customer/'+this.id+'?_method=PUT', data: formData, method: 'POST', headers: {'Content-Type': 'multipart/form-data'} })
          .then(resp => {
            this.$router.push('/klient/zobacz/'+this.id);
            this.$store.commit("snackbar", {
              text: "Wprowadzono zmiany",
              color: "primary",
              btn_color: "white"
            });
          })
          .catch(err => {
            this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
          })
          .then (() => {
            this.$store.commit('loader_off');
          })
      }
    },
    delete_user(){
      this.$store.commit('loader');
      this.$axios({url: this.$store.state.api +'/customer/'+this.id, data: {}, method: 'DELETE' })
        .then(resp => {
          this.$router.push('/klienci');
          this.$store.commit("snackbar", {
            text: "Usunięto użytkownika",
            color: "primary",
            btn_color: "white"
          });
        })
        .catch(err => {
          this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
        })
        .then (() => {
          this.$store.commit('loader_off');
        })
    }
  },
  mounted(){
    
    this.id = this.$route.params.id;

    // Edytowanie linku
    // console.log(this.breadcrumb[2].to)
    this.breadcrumb[2].to = '/klient/zobacz/'+this.id;

    this.$store.commit('loader');

    this.$axios({url: this.$store.state.api +'/customer/'+this.id, data: {}, method: 'GET' })
      .then(resp => {
        // Jezeli jest jakakolwiek osoba kontaktowa
        if(resp.data.user.contacts.length) this.contacts_person = resp.data.user.contacts;
        this.user_data = resp.data.user;
        this.role = resp.data.roles;
        this.$store.commit('loader_off');
      })
      .catch(err => {
        this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
      })
      .then (() => {
        this.$store.commit('loader_off');
      })
  }
};
</script>

<style lang="scss" scoped>
.card_section {
  margin-top: 25px;
}
</style>